<template>
  <div class="container">
    <b-row class="vh-100" align-v="center">
      <b-col cols="12" sm="12" md="8" offset-md="2" lg="6" offset-lg="3" xl="6" offset-xl="3">
        <b-card class="card-login shadow rounded mx-auto">
          <b-card-body class="flex flex-col items-center">
            <img class="login-logo" src="@/assets/logo-banner-green.png" />

            <Alert v-if="errorMessage" variant="red" class="mb-8" show>
              <strong>{{ errorMessage }}</strong> Having trouble? Email
              <a href="mailto:help@rafflebox.ca">help@rafflebox.ca</a>
            </Alert>

            <div v-if="success">
              <Alert variant="green" icon="check"> Your password was successfully reset. </Alert>

              <div class="text-center">
                <BaseButton size="lg" variant="success" @click.native="pushToLogin" class="m-auto mt-8">
                  Login
                </BaseButton>
              </div>
            </div>

            <b-form v-else class="w-100">
              <b-form-group :invalid-feedback="veeErrors.first('input-password')">
                <b-form-input
                  name="input-password"
                  placeholder="New Password"
                  type="password"
                  v-model="password"
                  v-validate="{ required: true, password: true }"
                  :state="validateState('input-password')"
                  data-vv-as="password"
                />
              </b-form-group>

              <b-form-group :invalid-feedback="veeErrors.first('input-confirm-password')">
                <b-form-input
                  autocomplete="new-password"
                  name="input-confirm-password"
                  placeholder="Confirm Password"
                  type="password"
                  v-model="confirmPassword"
                  v-validate="{ required: true, confirmed: password }"
                  :state="validateState('input-confirm-password')"
                  data-vv-as="password"
                />
              </b-form-group>

              <BaseButton size="lg" variant="success" :loading="busy" @click.native="submit" class="m-auto">
                Reset Password
              </BaseButton>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';
import Alert from '@/components/ui/Alert';
import BaseButton from '@/components/rbComponents/BaseButton.vue';

import UserServiceV2 from '@/lib/user-service-v2';

export default {
  components: {
    Alert,
    BaseButton
  },
  data() {
    return {
      token: '',
      confirmPassword: '',
      password: '',
      errorMessage: '',
      success: false,
      busy: false
    };
  },
  metaInfo: {
    title: 'Reset Password'
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    pushToLogin() {
      this.$router.push('/login');
    },
    async submit() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      this.errorMessage = '';
      this.busy = true;

      const token = this.$route.query.token;

      try {
        this.sleep(2000);

        await getAuth().checkActionCode(token);

        const resetPassword = {
          token,
          password: this.password
        };

        await UserServiceV2.completeResetPassword(resetPassword);

        this.success = true;
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'Reset password failed. Please contact support.';
        }
      } finally {
        this.busy = false;
      }
    }
  }
};
</script>

<style scoped>
.container {
  margin: auto;
}

.form-group {
  position: relative;
  margin-bottom: 2rem;
}

.form-control {
  margin-bottom: 0;
  /* 2.5em height, 0.75em padding, 2px border */
  height: calc(2.5em + 0.75em + 2px);
  border-radius: 0.5rem;
}

.login-logo {
  max-width: 100%;
  max-height: 100%;
  max-width: 80%;
  padding-bottom: 1.5rem;
}

.app-title {
  padding-bottom: 2rem;
}

.card-body {
  text-align: center;
  padding: 1.25rem;
}

.card {
  padding: 1.5rem;
}
</style>
